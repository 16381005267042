import React from "react";
import theme from "theme";
import { Theme, Text, Image, Box, LinkBox, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				Hakkında | Precision Eurorepar
			</title>
			<meta name={"description"} content={"Aracınızın Potansiyelinin Ortaya Çıktığı Yer"} />
			<meta property={"og:title"} content={"Hakkında | Precision Eurorepar"} />
			<meta property={"og:description"} content={"Aracınızın Potansiyelinin Ortaya Çıktığı Yer"} />
			<meta property={"og:image"} content={"https://stardustwhispers.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://stardustwhispers.com/img/325243634.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://stardustwhispers.com/img/325243634.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://stardustwhispers.com/img/325243634.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://stardustwhispers.com/img/325243634.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://stardustwhispers.com/img/325243634.png"} />
			<meta name={"msapplication-TileImage"} content={"https://stardustwhispers.com/img/325243634.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="80px 0" sm-padding="40px 0">
			<Override slot="SectionContent" align-items="center" />
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 50px 0">
			Hakkımızda
			</Text>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://stardustwhispers.com/img/1.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" sm-width="100%" lg-padding="0px 0px 0px 0px">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Hizmetlerimizi Keşfedin
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Precision Eurorepar olarak kapsamlı ve titiz servis hizmetlerimizle aracınızın ömrünü uzatmaya kararlıyız. Hizmetlerimiz sadece onarım için değil, aracınızın performansını ve güvenliğini optimize etmek için tasarlanmıştır. Düzenli bakımdan karmaşık arıza tespitine kadar sunduğumuz her hizmet aynı hassasiyet ve özenle gerçekleştirilir.
						</Text>
					</Box>
				</Box>
			</LinkBox>
			<LinkBox
				display="flex"
				margin="0px 0px 32px 0px"
				md-flex-direction="column"
				md-align-items="center"
				md-justify-content="center"
				flex-direction="row"
				align-items="flex-start"
				flex-wrap="wrap"
				lg-margin="0px 0px 16px 0px"
			>
				<Box
					width="40%"
					padding="0px 0px 0px 16px"
					display="flex"
					align-items="flex-start"
					justify-content="flex-start"
					md-width="100%"
					sm-align-items="flex-start"
					sm-flex-direction="column"
					sm-justify-content="flex-start"
					flex-direction="column"
					lg-width="100%"
					lg-flex-direction="row"
					lg-justify-content="space-between"
					lg-padding="16px 12px 16px 12px"
				>
					<Box padding="0px 50px 0px 0px" lg-width="70%" lg-padding="0px 0px 0px 0px" sm-width="100%">
						<Text margin="0px 0px 8px 0px" font="--headline3" color="--dark">
						Uzman Mekanisyenlerimiz
						</Text>
						<Text margin="0px 0px 0px 0px" font="--lead" color="--darkL2">
						Precision Eurorepar'ün bel kemiği uzman teknisyenlerden oluşan ekibimizdir. Her bir teknisyen ASE sertifikalıdır ve servis merkezimize engin deneyim ve uzmanlık bilgisi getirmektedir. Otomotiv teknolojisi ve tekniklerinin ön saflarında yer almak için sürekli eğitim ve gelişim sağlıyoruz. Teknisyenlerimiz sadece teknisyen değil, otomobil tutkunları ve otomotiv mükemmelliğinin savunucularıdır.
						</Text>
					</Box>
				</Box>
				<Box
					width="60%"
					padding="0px 16px 0px 0px"
					align-items="flex-start"
					display="flex"
					justify-content="flex-start"
					margin="0px 0px 0px 0px"
					md-width="100%"
					lg-width="100%"
					lg-padding="0px 0px 0px 0px"
				>
					<Image
						src="https://stardustwhispers.com/img/8.jpg"
						border-radius="24px"
						max-width="100%"
						max-height="456px"
						width="100%"
						object-fit="none"
						lg-max-height="392px"
						sm-max-height="213px"
					/>
				</Box>
			</LinkBox>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			sm-min-height="auto"
			display="flex"
			quarkly-title="Advantages/Features-7"
		>
			<Override
				slot="SectionContent"
				flex-direction="column"
				sm-min-width="280px"
				lg-flex-wrap="wrap"
				display="flex"
			/>
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				sm-margin="0px 0px 30px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-flex-direction="row"
				lg-flex-wrap="wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				margin="0px 0px 64px 0px"
			>
				<Text
					as="h2"
					margin="0px 0px 24px 0px"
					font="--headline2"
					color="--dark"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					sm-text-align="left"
				>
					Servis Tekliflerimiz
				</Text>
				<Text
					as="p"
					margin="0px 0px 0px 0px"
					font="--lead"
					color="--dark"
					width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					text-align="left"
					md-text-align="center"
					lg-width="100%"
					sm-text-align="left"
				>
					Precision Eurorepar, tüm otomotiv ihtiyaçlarınızı karşılamak için geniş bir hizmet yelpazesi sunar. İşte bekleyebilecekleriniz:
				</Text>
			</Box>
			<Box
				width="100%"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="48px"
				lg-grid-gap="48px"
				lg-width="100%"
				sm-grid-template-columns="1fr"
				lg-grid-template-columns="repeat(2, 1fr)"
			>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Rutin Bakım: Aracınızın sorunsuz çalışmasını sağlamak için düzenli servis.
					<br/><br/>
Yağ Değişimi: Motor performansını artırmak için yüksek kaliteli yağ ve filtre değişimleri.
					<br/><br/>
Lastik Rotasyonu: Lastiklerinizin ömrünü uzatın ve araç güvenliğini artırın.
					<br/><br/>
Fren Kontrolü: Frenlerinizin duyarlı ve güvenilir olmasını sağlamak için kapsamlı kontroller.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Gelişmiş Diyagnostik: Sorunları saptamak ve araç işlevini optimize etmek için en son teknoloji ekipmanların kullanılması.
					<br/><br/>
Motor Diyagnostiği: Motora özgü sorunları tespit etmek ve ele almak için kapsamlı analiz.
					<br/><br/>
Elektrik Sistemleri Testi: Aracınızın elektrik bileşenlerinin uzman değerlendirmesi.

					</Text>
				</Box>
				<Box align-items="flex-start" display="flex" flex-direction="column" sm-align-items="flex-start">
					<Text margin="8px 0px 0px 0px" font="--base" color="--dark" sm-text-align="left">
					Onarım Hizmetleri: Küçük ayarlamalardan büyük onarımlara kadar her şeyi hassasiyetle ele alıyoruz.
					<br/><br/>
Şanzıman Onarımı: Şanzımanınızı en iyi durumda tutmak için tam servis onarım ve bakım.
					<br/><br/>
Süspansiyon Sistemi Onarımı: Aracınızın konforunu ve yol tutuşunu iyileştirmek için geliştirmeler ve onarımlar.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Cta />
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});